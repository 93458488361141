function initFreshChat() {
  window.fcWidget.init({
    token: '5cf30597-2420-48d0-843a-9e4b473a8ba2',
    host: 'https://easyexpense-team.freshchat.com',
    tags: ['web', 'all'],
  });
}
function initialize(i, t) {
  var e;
  i.getElementById(t)
    ? initFreshChat()
    : (((e = i.createElement('script')).id = t),
      (e.async = !0),
      (e.src = 'https://easyexpense-team.freshchat.com/js/widget.js'),
      (e.onload = initFreshChat),
      i.head.appendChild(e));
}
function initiateCall() {
  initialize(document, 'Freshchat-js-sdk');
}
window.addEventListener
  ? window.addEventListener('load', initiateCall, !1)
  : window.attachEvent('load', initiateCall, !1);
